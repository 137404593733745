@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-benefit-program-information-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-benefit-program-information {
    .benefit-name {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-program-information--benefit-name-typeface-mobile',
        'emphasis-3-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-program-information--benefit-name-typeface-desktop',
          'emphasis-3-desktop'
        );
      }
    }

    .benefit-description,
    .find-out-more,
    .terms-and-conditions {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-program-information--text-typeface-mobile',
        'text-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-program-information--text-typeface-desktop',
          'text-desktop'
        );
      }
    }
  }
}
