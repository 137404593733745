@import 'mixins';
@import 'wl-typography-utils';

@mixin widgets-checkout-wizard-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-shared-checkout-wizard {
    .checkout-wizard-list-item {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rd-widgets-checkout-wizard--checkout-wizard-list-item-typeface',
        'caption-desktop'
      );

      .checkout-wizard-list-item-number {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rd-widgets-checkout-wizard--checkout-wizard-list-item-number-typeface',
          'caption-emphasis-desktop'
        );
      }
    }
  }
}
