@import 'wl-typography-utils';

@mixin rd-rc-success-buttons-typography($base-typography, $tenant-typography) {
  .rd-rc-success-buttons {
    .buttons {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-buttons--buttons-typeface',
        'text-emphasis-2-desktop'
      );
    }
  }
}
