@import 'wl-color-utils';

@mixin rd-rc-review-form-theme($color-theme, $component-theme) {
  $rc-review-form--error-message-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-form--error-message-color,
    error
  );
  $rc-review-form--terms-link-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-form--terms-link-text-color,
    link-100
  );
  $rc-review-form--terms-link-text-active-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-form--terms-link-text-active-color,
    primary-200
  );
  $rc-review-form--terms-link-text-hover-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-form--terms-link-text-hover-color,
    primary-300
  );
  $rc-review-form--terms-link-outline-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-form--terms-link-outline-color,
    focus
  );

  .rd-rc-review-form {
    .error-message {
      color: $rc-review-form--error-message-color;
    }

    .terms-link {
      color: $rc-review-form--terms-link-text-color;

      &:hover {
        color: $rc-review-form--terms-link-text-hover-color;
      }

      &:active {
        color: $rc-review-form--terms-link-text-active-color;
      }

      &:focus {
        outline-color: $rc-review-form--terms-link-outline-color;
      }
    }
  }
}
