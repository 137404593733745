@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-benefit-details-form-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-benefit-details-form {
    .details-disclaimer {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-details-form--checkout-disclaimer-typeface-mobile',
        'text-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-details-form--checkout-disclaimer-typeface-desktop',
          'text-desktop'
        );
      }
    }
  }
}
