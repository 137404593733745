@import 'wl-color-utils';

// reuse the legacy name `rd-rc-success-theme` to avoid breaking changes
@mixin rd-rc-success-theme($color-theme, $component-theme) {
  $rc-success--loading-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-success--loading-background-color,
    'background'
  );

  .rd-rc-success {
    .loading-background {
      background-color: $rc-success--loading-background-color;
    }
  }
}
