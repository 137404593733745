@import 'wl-typography-utils';

@mixin rd-rc-checkout-form-typography($base-typography, $tenant-typography) {
  .rd-rc-checkout-form {
    .redeem-cash-checkout-title {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-checkout-form--title-typeface',
        'text-emphasis-1-desktop'
      );
    }

    .redeem-cash-checkout-subtitle,
    .redeem-cash-checkout-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-checkout-form--text-typeface',
        'text-desktop'
      );
    }
  }
}
