@import 'wl-color-utils';

@mixin rd-rc-checkout-terms-list-theme($color-theme, $component-theme) {
  $rc-checkout-terms-list--background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-checkout-terms-list--background-color,
    primary-200
  );

  .rd-rc-checkout-terms-list {
    .redeem-cash-terms-list-container {
      background-color: $rc-checkout-terms-list--background-color;
    }
  }
}
