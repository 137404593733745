@use '@angular/material' as mat;
@import 'rd-global';
@import './fab-fonts';
@import './fab-theme';
@import './fab-typography';
@import './fab-tenant-variables';
@import 'third-party-libs/third-party-libs';

@import './fab-material-theme';

@include mat.core();

$fab-primary: mat.define-palette($primary-theme, 100, 200, 300, 400);
$fab-accent: mat.define-palette($accent-theme, 100, 200, 300, 400);

// The warn palette is optional (defaults to red).
$fab-warn: mat.define-palette($warn-theme);

// Copy the same colors in material, but override slider-min, slider-off, and slider-off-active for the sliders
// Check Angular Material's components/src/material/core/theming/_palette.scss
mat.$light-theme-foreground-palette: (
  base: black,
  divider: rgba(black, 0.12),
  dividers: rgba(black, 0.12),
  disabled: rgba(black, 0.38),
  disabled-button: rgba(black, 0.26),
  disabled-text: rgba(black, 0.38),
  elevation: black,
  hint-text: rgba(black, 0.38),
  secondary-text: rgba(black, 0.54),
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: $grey-600,
  slider-off: $grey-600,
  slider-off-active: $grey-600
);

// Create the theme object (a Sass map containing all of the palettes).
$fab-theme: mat.define-light-theme($fab-primary, $fab-accent, $fab-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fab-theme);

$custom-typography: mat.define-typography-config(
  $font-family: 'FSMatthew, "Helvetica Neue", Helvetica, Arial, sans-serif',
);

@include mat.all-component-typographies($custom-typography);

body {
  background-color: $grey-600;
}

body,
button,
input,
.pika-single {
  font-family: FSMatthew, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

// Overrides specific to FAB-only

// This corrects misalignment in the PA page chart because of misaligned FAB font
.rd-pt-chart-legend {
  .legend-box {
    margin-bottom: 4px;
  }
}

@include rd-core-module-theme($color-theme, $component-theme);
@include rd-shared-module-theme($color-theme, $component-theme);
@include rd-redeem-cash-module-theme($color-theme, $component-theme);

[override] {
  rd-gc-success-points-transaction-status {
    .gift-card-status-text {
      font-weight: 400;
    }
  }
}

.rd-shared-link {
  display: flex;

  .link {
    align-items: center;
    display: flex;
    font-size: 1rem;
    font-weight: 100;
    text-decoration: none;

    &:after {
      display: none;
    }

    &:focus {
      margin-left: 2px;
      outline-width: 2px;
      outline-style: solid;
    }

    .forward-arrow {
      stroke: $red-100;
    }

    &:hover {
      .forward-arrow {
        stroke: $blue-300;
      }
    }

    &:active {
      .link-text {
        color: $blue-600;
      }

      .forward-arrow {
        stroke: $blue-600;
      }
    }
  }
  .link-text {
    &:focus {
      outline: 0;
    }

    .ltr & {
      margin-right: 10px;
    }

    .rtl & {
      margin-left: 10px;
    }
  }

  .button-link {
    cursor: pointer;
  }

  #icon-link-arrow {
    .rtl & {
      transform: rotate(180deg);
    }
  }

  .rd-shared-icon {
    width: 10px;
    height: 10px;
  }
}

.rd-core-main-container {
  // Shared button styles
  .rd-shared-button {
    .rd-button {
      border-radius: var(--button-radius);
      font-weight: 100;
    }

    #arrow_red_R {
      :host-context(.rtl) & {
        transform: rotate(180deg);
      }
    }

    // Outlined buttons - smaller radius, to completely cover the button with background-color when hovering
    $button-types: 'primary', 'secondary', 'warn', 'error';
    @each $button-type in $button-types {
      &.outline-#{$button-type},
      &.transparent-#{$button-type} {
        border-radius: var(--button-radius);
        .rd-button {
          border-radius: var(--button-outline-radius);
        }
      }
    }
  }
}

:root {
  --button-radius: 12px 2px;
  --button-outline-radius: 10px 0;
  // GC checkout page
  --gc-checkout-amount-form--radio-button-height: 44px;

  // [Shared] Radio Pills component:
  --shared-radio-pills--border-radius-width: 12px 2px;
  --shared-radio-pills--text-color: #{$grey-500};
  --shared-radio-pills--text-selected-color: white;
  --shared-radio-pills--bg-selected-color: #{$blue-400};
  --shared-radio-pills--border-color: #{$blue-400};
  --shared-radio-pills--border-selected-color: transparent;
  --shared-radio-pills--hover-border-color: #{$blue-400};
  --shared-radio-pills--hover-color: #{rgba($grey-500, 0.8)};
  --shared-radio-pills--focus-color: transparent;
}

.rtl {
  --button-radius: 2px 12px;
  --button-outline-radius: 0 10px;

  --shared-radio-pills--border-radius-width: 2px 12px;
}
