@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-rc-review-body-typography($base-typography, $tenant-typography) {
  .rd-rc-review-body {
    .review-body-title {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-body--title-typeface-mobile',
        'emphasis-3-mobile'
      );

      @include screen-until-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rc-review-body--body-title-typeface-desktop',
          'text-emphasis-2-desktop'
        );
      }
    }

    .helper-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-body--helper-text-typeface',
        'text-desktop'
      );
    }
  }
}
