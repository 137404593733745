@import 'fab-typefaces';
@import 'rd-typography';
@import 'wl-typography-utils';
@import 'map-utils';
@import '../app/redeem-cash/stylesheets/redeem-cash.module.typography';

$base-typography: (
  // Desktop
  'heading-1-desktop': map-get($fab-typefaces, 'heading-50-bold'),
  'heading-2-desktop': map-get($fab-typefaces, 'heading-44-bold'),
  'heading-3-desktop': map-get($fab-typefaces, 'heading-21-bold'),
  'emphasis-1-desktop': map-get($fab-typefaces, 'body-38-regular'),
  'emphasis-2-desktop': map-get($fab-typefaces, 'body-28-light'),
  'emphasis-3-desktop': map-get($fab-typefaces, 'body-30-light'),
  'emphasis-4-desktop': map-get($fab-typefaces, 'body-24-light'),
  'emphasis-5-desktop': map-get($fab-typefaces, 'body-22-regular'),
  'emphasis-6-desktop': map-get($fab-typefaces, 'body-20-regular'),
  'emphasis-7-desktop': map-get($fab-typefaces, 'body-20-light'),
  'emphasis-8-desktop': map-get($fab-typefaces, 'body-18-regular'),
  'emphasis-9-desktop': map-get($fab-typefaces, 'body-18-light'),
  'text-desktop': map-get($fab-typefaces, 'text-16-light'),
  'text-emphasis-1-desktop': map-get($fab-typefaces, 'text-16-bold'),
  'text-emphasis-2-desktop': map-get($fab-typefaces, 'text-16-regular'),
  'small-text-desktop': map-get($fab-typefaces, 'small-text-14-light'),
  'small-text-emphasis-1-desktop': map-get($fab-typefaces, 'small-text-14-bold'),
  'small-text-emphasis-2-desktop':
    map-get($fab-typefaces, 'small-text-14-regular'),
  'caption-desktop': map-get($fab-typefaces, 'caption-12-regular'),
  'caption-emphasis-desktop': map-get($fab-typefaces, 'caption-12-bold'),
  // Mobile
  'heading-1-mobile': map-get($fab-typefaces, 'heading-24-bold'),
  'heading-2-mobile': map-get($fab-typefaces, 'heading-32-bold'),
  'heading-3-mobile': map-get($fab-typefaces, 'caption-12-bold'),
  'emphasis-1-mobile': map-get($fab-typefaces, 'body-22-regular'),
  'emphasis-2-mobile': map-get($fab-typefaces, 'text-16-light'),
  'emphasis-3-mobile': map-get($fab-typefaces, 'body-22-light'),
  'emphasis-4-mobile': map-get($fab-typefaces, 'body-22-regular'),
  'emphasis-5-mobile': map-get($fab-typefaces, 'text-16-bold'),
  'emphasis-6-mobile': map-get($fab-typefaces, 'text-16-regular'),
  'emphasis-7-mobile': map-get($fab-typefaces, 'body-18-light'),
  'emphasis-8-mobile': map-get($fab-typefaces, 'text-16-regular'),
  'emphasis-9-mobile': map-get($fab-typefaces, 'text-16-light'),
  'text-mobile': map-get($fab-typefaces, 'text-16-light'),
  'text-emphasis-1-mobile': map-get($fab-typefaces, 'text-16-bold'),
  'text-emphasis-2-mobile': map-get($fab-typefaces, 'text-16-regular'),
  'small-text-mobile': map-get($fab-typefaces, 'small-text-14-light'),
  'small-text-emphasis-1-mobile': map-get($fab-typefaces, 'small-text-14-bold'),
  'small-text-emphasis-2-mobile':
    map-get($fab-typefaces, 'small-text-14-regular'),
  'caption-mobile': map-get($fab-typefaces, 'caption-12-regular'),
  'caption-emphasis-mobile': map-get($fab-typefaces, 'caption-12-bold')
);

$core-module-typography: (
  'core-auth-session-expired-dialog--error-message-typeface-desktop':
    map-get($fab-typefaces, 'body-20-regular'),
  'core-auth-session-expired-dialog--error-message-typeface-mobile':
    map-get($fab-typefaces, 'body-20-regular'),
  'core-auth-session-expired-dialog--close-message-typeface-mobile':
    map-get($fab-typefaces, 'text-16-light'),
  'core-auth-session-expired-dialog--close-message-typeface-desktop':
    map-get($fab-typefaces, 'text-16-light'),
);

$payment-module-typography: (
  'payment-form--typeface-desktop':
    map-get($base-typography, 'text-emphasis-2-desktop'),
  'payment-form--typeface-mobile':
    map-get($base-typography, 'text-emphasis-2-desktop'),
  'payment-card-detail-form-typeface':
    map-get($base-typography, 'text-emphasis-2-desktop'),
  'payment-card-detail-form--selected-tab-typeface-desktop':
    map-get($base-typography, 'emphasis-7-desktop'),
);

$shared-module-typography: (
  'shared-cash-point-slider--label-typeface':
    map-get($base-typography, 'small-text-emphasis-2-mobile'),
  'shared-checkout-error-dialog--title-typeface-desktop':
    map-get($fab-typefaces, 'body-20-light'),
  'shared-checkout-error-dialog--title-typeface-mobile':
    map-get($fab-typefaces, 'body-18-light'),
  'shared-checkout-error-dialog--description-typeface-desktop':
    map-get($base-typography, 'emphasis-7-desktop'),
  'shared-checkout-error-dialog--description-typeface-mobile':
    map-get($base-typography, 'emphasis-7-mobile'),
);

$gift-card-module-typography: (
  'gc-checkout-amount-form--amount-form-title-typeface-desktop':
    map-get($fab-typefaces, 'text-16-bold'),
  'gc-checkout-amount-form--amount-form-title-typeface-mobile':
    map-get($fab-typefaces, 'text-16-bold'),
  'gc-gift-cards-list--empty-gift-cards-title-typeface-desktop':
    map-get($fab-typefaces, 'body-24-regular'),
  'gc-gift-cards-list--empty-gift-cards-title-typeface-mobile':
    map-get($fab-typefaces, 'body-20-regular'),
  'gc-review-breakdown--item-label-typeface-mobile':
    map-get($base-typography, 'text-mobile'),
  'gc-review-breakdown--item-label-typeface-desktop':
    map-get($base-typography, 'text-desktop'),
  'gc-review-form--pay-with-text-typeface-desktop':
    map-get($base-typography, 'emphasis-6-desktop'),
  'gc-review-form--pay-with-text-typeface-mobile':
    map-get($base-typography, 'emphasis-5-mobile'),
  'gc-success-detail--gift-card-currency-amount-typeface-desktop':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'gc-success-detail--gift-card-currency-amount-typeface-mobile':
    map-get($base-typography, 'text-emphasis-1-mobile'),
  'gc-success-detail--gift-card-product-name-typeface-desktop':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'gc-success-detail--gift-card-product-name-typeface-mobile':
    map-get($base-typography, 'text-emphasis-1-mobile'),
  'gc-success-points-transaction-status--gift-card-contact-us-strong-typeface-desktop':
    map-get($fab-typefaces, 'body-18-bold'),
  'gc-success-points-transaction-status--gift-card-contact-us-strong-typeface-mobile':
    map-get($fab-typefaces, 'text-16-bold'),
  'gc-success-points-transaction-status--gift-card-will-notify-emphasis-typeface-desktop':
    map-get($fab-typefaces, 'body-18-bold'),
  'gc-success-points-transaction-status--gift-card-will-notify-emphasis-typeface-mobile':
    map-get($fab-typefaces, 'text-16-bold'),
);

$points-transaction-module-typography: (
  'pt-history-list-item--date-text-typeface-mobile':
    map-get($base-typography, 'caption-mobile'),
  'pt-history-list-desktop--empty-points-activities-title-typeface':
    map-get($fab-typefaces, 'body-24-regular'),
  'pt-history-list-mobile--empty-points-activities-title-typeface':
    map-get($fab-typefaces, 'body-20-regular'),
);

$redeem-cash-module-typography: ();

$popup-module-typography: ();

$benefit-module-typography: (
  'benefit-banner--subtitle-typeface':
    map-get($base-typography, 'heading-3-desktop'),
  'benefit-details-form--checkout-disclaimer-typeface-desktop':
    map-get($base-typography, 'small-text-desktop'),
  'benefit-details-form--checkout-disclaimer-typeface-mobile':
    map-get($base-typography, 'small-text-mobile'),
  'benefit-program-information--benefit-name-typeface-desktop':
    map-get($base-typography, 'text-emphasis-1-desktop'),
  'benefit-program-information--benefit-name-typeface-mobile':
    map-get($base-typography, 'text-emphasis-1-mobile'),
  'benefit-success-points-transaction-status--status-text-typeface-desktop':
    map-get($fab-typefaces, 'body-30-regular'),
  'benefit-success-points-transaction-status--status-text-typeface-mobile':
    map-get($fab-typefaces, 'body-22-regular'),
  'benefit-success-points-transaction-status--status-description-emphasis-typeface-desktop':
    map-get($fab-typefaces, 'body-18-bold'),
  'benefit-success-points-transaction-status--status-description-emphasis-typeface-mobile':
    map-get($fab-typefaces, 'text-16-bold'),
  'benefit-success-points-transaction-status--status-description-typeface-desktop':
    map-get($base-typography, 'emphasis-9-desktop'),
  'benefit-success-points-transaction-status--status-description-typeface-mobile':
    map-get($base-typography, 'emphasis-9-mobile'),
);

$tenant-typography: map-collect(
  $core-module-typography,
  $shared-module-typography,
  $redeem-cash-module-typography,
  $popup-module-typography,
  $benefit-module-typography
);

@include generate-typography-classes($base-typography);
@include rd-core-module-typography($base-typography, $tenant-typography);
@include rd-shared-module-typography($base-typography, $tenant-typography);
@include rd-benefit-module-typography($base-typography, $tenant-typography);
@include rd-redeem-cash-module-typography($base-typography, $tenant-typography);
@include widgets-checkout-wizard-typography(
  $base-typography,
  $tenant-typography
);

:root {
  @include generate-typography-variable-from-map(
    $base-typography,
    'FSMatthew, "Helvetica Neue", Helvetica, Arial, sans-serif'
  );

  @include generate-typography-variable-from-map(
    $points-transaction-module-typography,
    'FSMatthew, "Helvetica Neue", Helvetica, Arial, sans-serif'
  );

  @include generate-typography-variable-from-map(
    $gift-card-module-typography,
    'FSMatthew, "Helvetica Neue", Helvetica, Arial, sans-serif'
  );

  // Payment typography variables
  @include generate-typography-variable-from-map(
    $payment-module-typography,
    'FSMatthew, "Helvetica Neue", Helvetica, Arial, sans-serif'
  );
}
