@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-benefit-banner-typography($base-typography, $tenant-typography) {
  .rd-benefit-banner {
    .banner-title {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-banner--title-typeface-mobile',
        'heading-1-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-banner--title-typeface-desktop',
          'heading-1-desktop'
        );
      }
    }

    .banner-subtitle {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-banner--subtitle-typeface',
        'text-desktop'
      );
    }
  }
}
