$asset-font-path: '../assets/fonts';

@font-face {
  font-family: 'FSMatthew';
  font-weight: 100;
  font-style: light;
  src: url('#{$asset-font-path}/FSMatthew-Light.woff2') format('woff2'),
    url('#{$asset-font-path}/FSMatthew-Light.woff') format('woff'),
    url('#{$asset-font-path}/FSMatthew-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'FSMatthew';
  font-weight: 300;
  font-style: normal;
  src: url('#{$asset-font-path}/FSMatthew-Light.woff2') format('woff2'),
    url('#{$asset-font-path}/FSMatthew-Light.woff') format('woff'),
    url('#{$asset-font-path}/FSMatthew-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'FSMatthew';
  font-weight: 400;
  font-style: normal;
  src: url('#{$asset-font-path}/FSMatthew.woff2') format('woff2'),
    url('#{$asset-font-path}/FSMatthew.woff') format('woff'),
    url('#{$asset-font-path}/FSMatthew.ttf') format('truetype');
}

@font-face {
  font-family: 'FSMatthew';
  font-weight: 500;
  font-style: normal;
  src: url('#{$asset-font-path}/FSMatthew.woff2') format('woff2'),
    url('#{$asset-font-path}/FSMatthew.woff') format('woff'),
    url('#{$asset-font-path}/FSMatthew.ttf') format('truetype');
}

@font-face {
  font-family: 'FSMatthew';
  font-weight: 600;
  font-style: normal;
  src: url('#{$asset-font-path}/FSMatthew-Bold.woff2') format('woff2'),
    url('#{$asset-font-path}/FSMatthew-Bold.woff') format('woff'),
    url('#{$asset-font-path}/FSMatthew-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 100;
  font-style: light;
  src: url('#{$asset-font-path}/Graphik-Thin.woff');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 300;
  font-style: normal;
  src: url('#{$asset-font-path}/Graphik-Thin.woff');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 400;
  font-style: normal;
  src: url('#{$asset-font-path}/Graphik-Regular.woff');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 500;
  font-style: normal;
  src: url('#{$asset-font-path}/Graphik-Regular.woff');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 600;
  font-style: normal;
  src: url('#{$asset-font-path}/Graphik-Semibold.woff');
}
