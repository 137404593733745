@import 'wl-typography-utils';

@mixin rd-rc-checkout-terms-list-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-rc-checkout-terms-list {
    .redeem-cash-terms-list-item-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-checkout-terms-list--item-text-typeface',
        'text-emphasis-2-desktop'
      );
    }
  }
}
