@import 'wl-color-utils';

@mixin rd-rc-review-breakdown-theme($color-theme, $component-theme) {
  $rc-review-breakdown--computation-list-item-final-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-breakdown--computation-list-item-final-color,
    primary-400
  );
  $rc-review-breakdown--highlight-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-breakdown--highlight-bg-color,
    'warn'
  );
  $rc-review-breakdown--divider-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-breakdown--divider-border-color,
    grey-200
  );

  .rd-rc-review-breakdown {
    .review-computation-table {
      &:after {
        border-color: $rc-review-breakdown--divider-border-color;
      }
    }

    .review-computation-list-item {
      &.highlighted {
        background-color: rgba($rc-review-breakdown--highlight-bg-color, 0.1);
      }

      &.final {
        color: $rc-review-breakdown--computation-list-item-final-color;
      }
    }
  }
}
