@import 'wl-color-utils';

@mixin rd-rc-checkout-transfer-amount-form-theme(
  $color-theme,
  $component-theme
) {
  $rc-checkout-transfer-amount-form--target-currency-label-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-checkout-transfer-amount-form--target-currency-label-color,
    primary-400
  );

  .rd-rc-checkout-transfer-amount-form {
    .mat-mdc-form-field:not(.mat-form-field-invalid) {
      .target-currency-label,
      .target-amount input {
        color: $rc-checkout-transfer-amount-form--target-currency-label-color;
      }
    }
  }
}
