@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-rc-success-points-transaction-status-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-rc-success-points-transaction-status {
    .redeem-cash-status-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-points-transaction-status--status-text-typeface-desktop',
        'emphasis-3-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rc-success-points-transaction-status--status-text-typeface-mobile',
          'emphasis-1-mobile'
        );
      }
    }

    .redeem-cash-redemption-id {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-points-transaction-status--redemption-id-typeface-desktop',
        'emphasis-8-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rc-success-points-transaction-status--redemption-id-typeface-mobile',
          'emphasis-6-mobile'
        );
      }
    }

    .redeem-cash-disclaimer {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-points-transaction-status--cash-disclaimer-typeface-desktop',
        'emphasis-8-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'rc-success-points-transaction-status--cash-disclaimer-typeface-desktop',
          'emphasis-5-mobile'
        );
      }
    }
  }
}
