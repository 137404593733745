@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-benefit-success-points-transaction-status-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-benefit-success-points-transaction-status {
    .status-text {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-success-points-transaction-status--status-text-typeface-mobile',
        'emphasis-1-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-success-points-transaction-status--status-text-typeface-desktop',
          'heading-2-desktop'
        );
      }
    }

    .status-description {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-success-points-transaction-status--status-description-typeface-mobile',
        'text-mobile'
      );

      @include screen-from-desktop {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-success-points-transaction-status--status-description-typeface-desktop',
          'text-desktop'
        );
      }

      .emphasis {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-success-points-transaction-status--status-description-emphasis-typeface-mobile',
          'text-emphasis-1-mobile'
        );

        @include screen-from-desktop {
          @include rd-typography-style(
            $base-typography,
            $tenant-typography,
            'benefit-success-points-transaction-status--status-description-emphasis-typeface-desktop',
            'text-emphasis-1-desktop'
          );
        }
      }
    }
  }
}
