$white: #ffffff;

$black: #000;

$blue-100: #f5f9ff;
$blue-200: #e6f0ff;
$blue-300: #7eacff;
$blue-400: #003da6;
$blue-500: #0c2340;
$blue-600: #6a7b8f;
$blue-700: #11233d;

$green-100: #ebfbf2;
$green-200: #3edb7b;
$green-300: #ecf5f1;

$grey-100: #d5d8d8;
$grey-200: #969696;
$grey-300: #7f7f7f;
$grey-400: #484848;
$grey-500: #4d4e53;
$grey-600: #f6f7f8;
$grey-700: #e0e3e7;
$grey-800: #fafcff;
$grey-900: #3b3b3b;
$grey-1000: #4a4a4a;
$slate-grey: #637487;

$red-100: #ff251b;
$red-200: #e93936;
$red-300: #ea1821;
$red-400: #fdefef;

$orange-100: #f6871f;
$orange-200: #ec6807;
$orange-300: #fef3e8;
$orange-400: #e8ddd0;
