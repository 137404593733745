@import 'wl-color-utils';

@mixin rd-rc-review-body-theme($color-theme, $component-theme) {
  $rc-review-body--host-bg-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-body--host-bg-color,
    'background'
  );
  $rc-review-body--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-body--text-color,
    'text'
  );
  $rc-review-body--review-body-information-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-review-body--review-body-information-border-color,
    grey-200
  );

  .rd-rc-review-body {
    color: $rc-review-body--text-color;

    .points-transfer-details {
      background-color: $rc-review-body--host-bg-color;
    }

    .review-body-information {
      border-color: $rc-review-body--review-body-information-border-color;
    }
  }
}
