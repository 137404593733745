@import 'wl-typography-utils';

@mixin rd-rc-review-breakdown-typography($base-typography, $tenant-typography) {
  .rd-rc-review-breakdown {
    .review-computation-list-item-description {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-breakdown--list-item-description-typeface',
        'text-desktop'
      );
    }

    .review-computation-list-item-amount {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-breakdown--list-item-amount-typeface',
        'text-emphasis-1-desktop'
      );
    }
  }
}
