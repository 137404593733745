@import 'wl-typography-utils';

@mixin rd-rc-review-form-typography($base-typography, $tenant-typography) {
  .rd-rc-review-form {
    .checkbox-label {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-form--checkbox-label-typeface',
        'text-desktop'
      );
    }

    .submit-button {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-form--submit-button-typeface',
        'text-emphasis-1-desktop'
      );
    }

    .error-message {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-review-form--submit-button-typeface',
        'small-text-emphasis-2-desktop'
      );
    }
  }
}
