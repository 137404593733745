@import 'wl-color-utils';

@mixin rd-rc-success-points-transaction-status-theme(
  $color-theme,
  $component-theme
) {
  $rc-success-points-transaction-status--border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-success-points-transaction-status--border-color,
    grey-100
  );
  $rc-success-points-transaction-status--redemption-id-text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-success-points-transaction-status--redemption-id-text-color,
    primary-400
  );

  .rd-rc-success-points-transaction-status {
    &:after {
      border-bottom-color: $rc-success-points-transaction-status--border-color;
    }

    .redeem-cash-redemption-id {
      color: $rc-success-points-transaction-status--redemption-id-text-color;
    }
  }
}
