@import 'wl-color-utils';

@mixin rd-rc-checkout-form-theme($color-theme, $component-theme) {
  $rc-checkout-form--container-background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-checkout-form--container-background-color,
    'background'
  );
  $rc-checkout-form--section-border-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-checkout-form--section-border-color,
    primary-100
  );
  $rc-checkout-form--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-checkout-form--text-color,
    grey-500
  );

  .rd-rc-checkout-form {
    .checkout-form-inner-container {
      background-color: $rc-checkout-form--container-background-color;
      color: $rc-checkout-form--text-color;
    }

    .rd-rc-checkout-transfer-amount-form {
      border-top-color: $rc-checkout-form--section-border-color;
    }
  }
}
