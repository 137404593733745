@import 'wl-typography-utils';

@mixin rd-rc-checkout-transfer-amount-form-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-rc-checkout-transfer-amount-form {
    input.mat-mdc-input-element {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-checkout-transfer-amount-form--mat-input-typeface',
        'emphasis-5-desktop'
      );
      line-height: 1;
    }

    .amount-form-label {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-checkout-transfer-amount-form--amount-label-typeface',
        'text-emphasis-1-desktop'
      );
    }

    .mat-mdc-form-field-hint,
    .mat-mdc-form-field-error {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-checkout-transfer-amount-form--mat-hint-typeface',
        'small-text-emphasis-2-desktop'
      );
    }
  }
}
