@import 'rd-theme';
@import 'map-utils';
@import './fab-colors';
@import '../app/redeem-cash/stylesheets/redeem-cash.module.theme';

$color-theme: (
  primary-100: $blue-100,
  primary-200: $blue-200,
  primary-300: $blue-300,
  primary-400: $blue-400,
  primary-500: $blue-500,
  primary-600: $blue-600,
  secondary-100: $red-100,
  secondary-200: $red-200,
  secondary-300: $red-300,
  secondary-400: $red-400,
  grey-100: $grey-100,
  grey-200: $grey-200,
  grey-300: $grey-300,
  grey-400: $grey-400,
  grey-500: $grey-500,
  grey-600: $grey-600,
  grey-700: $grey-700,
  grey-800: $grey-800,
  grey-900: $grey-900,
  error: $red-200,
  background: $white,
  'disabled': $grey-100,
  text: $grey-500,
  warn: $orange-100,
  focus: $orange-100,
  success: $blue-400,
  progress: $green-100,
  shadow: $black,
  tooltip: $blue-100,
  link-100: $blue-400,
  link-200: $blue-500,
  'transparent': transparent,
  'white': $white,
);

$core-module-theme: (
  core-main-header--text-color: map-get($color-theme, 'background'),
  core-main-header--dropdown-loading-background-color:
    map-get($color-theme, grey-100),
  core-auth-session-expired-dialog--error-message-font-color:
    map-get($color-theme, grey-500),
);

$dashboard-module-theme: (
  dashboard-banner--title-color: map-get($color-theme, grey-500),
);

$shared-module-theme: (
  shared-button--primary-background-color: map-get($color-theme, primary-400),
  shared-button--primary-background-hover-color:
    map-get($color-theme, primary-300),
  shared-button--primary-text-disabled-color:
    map-get($color-theme, 'background'),
  shared-button--secondary-background-color: map-get($color-theme, 'background'),
  shared-button--secondary-background-hover-color:
    map-get($color-theme, grey-100),
  shared-button--secondary-text-color: map-get($color-theme, primary-400),
  shared-button--secondary-text-hover-color: map-get($color-theme, primary-400),
  shared-carousel--button-border-color: map-get($color-theme, grey-100),
  shared-carousel--inner-item-container-border-color:
    map-get($color-theme, secondary-100),
  shared-carousel--button-active-color:
    rgba(map-get($color-theme, primary-200), 0.5),
  shared-carousel--button-active-icon-color: map-get($color-theme, primary-400),
  shared-cash-point-slider--bg-color: $orange-300,
  shared-cash-point-slider--text-color: map-get($color-theme, primary-500),
  shared-cash-point-slider--thumb-color: map-get($color-theme, primary-500),
  shared-cash-point-slider--thumb-ring-color: $orange-300,
  shared-cash-point-slider--track-bg-color: $orange-400,
  shared-cash-point-slider--track-filled-bg-color:
    map-get($color-theme, primary-500),
  shared-checkout-error-dialog--title-color: map-get($color-theme, text),
  shared-error-sign--text-color: map-get($color-theme, 'background'),
  shared-fulfillment-processing-loader--processing-text-color:
    map-get($color-theme, 'text'),
  shared-keyword-filter--keyword-search-bg-color:
    rgba(map-get($color-theme, grey-100), 0.3),
  shared-keyword-filter--keyword-search-border-color:
    map-get($color-theme, 'transparent'),
  shared-keyword-filter--keyword-search-placeholder-color:
    map-get($color-theme, grey-500),
  shared-pagination--active-page-background-color:
    map-get($color-theme, primary-400),
);

$payment-module-theme: (
  'payment-form--saved-cards-border-mobile-color': #e2e2e2,
  'payment-form--saved-cards-radio-button-checked-border-desktop-color':
    map-get($color-theme, primary-400),
  'payment-form--saved-cards-radio-button-checked-border-mobile-color': #e2e2e2,
  'payment-form--tab-radio-header-background-color':
    map-get($color-theme, primary-100),
  'payment-form--tab-radio-header-border-bottom-color':
    map-get($color-theme, 'white'),
  'payment-card-detail-form--stripe-input-normal':
    map-get($color-theme, grey-100),
  'payment-card-detail-form--stripe-input-placeholder-color':
    map-get($color-theme, grey-200),
  'payment-card-detail-form--stripe-input-primary':
    map-get($color-theme, primary-400),
);

$gift-card-module-theme: (
  gc-checkout-amount-form--points-currency-background-color: $orange-300,
  gc-checkout-form--container-background-color:
    map-get($color-theme, 'transparent'),
  gc-checkout-form--gc-checkout-terms-and-conditions-container-bg-color:
    map-get($color-theme, 'transparent'),
  gc-checkout-form--gc-checkout-terms-and-conditions-container-loading-bg-color:
    map-get($color-theme, grey-100),
  gc-checkout-form--gc-checkout-terms-list-container-color:
    map-get($color-theme, primary-200),
  gc-checkout-recipient-detail-form--input-field-placeholder-text-color:
    map-get($color-theme, 'grey-200'),
  gc-checkout-terms-and-conditions--terms-and-condition-text-color:
    map-get($color-theme, primary-400),
  gc-checkout-terms-list--list-item-loading-bg-color:
    map-get($color-theme, grey-100),
  gc-checkout-terms-list--terms-and-condition-button-active-color:
    map-get($color-theme, primary-600),
  gc-checkout-terms-list--terms-link-container-bg-color:
    map-get($color-theme, primary-100),
  gc-gift-cards-list-item-desktop--loading-grey-background-color:
    map-get($color-theme, grey-100),
  gc-gift-cards-list-item-mobile--loading-grey-background-color:
    map-get($color-theme, grey-100),
  gc-review-form--pay-with-text-color: map-get($color-theme, 'text'),
  gc-review-body--total-charges-bg-color: rgba(map-get($color-theme, warn), 10%),
  gc-review-form--terms-link-text-active-color:
    map-get($color-theme, primary-600),
  gc-review-recipient-details--container-border-top-color:
    map-get($color-theme, grey-100),
  gc-terms-and-condition-dialog--link-text-active-color:
    map-get($color-theme, primary-600),
  gc-terms-and-condition-dialog--title-background-color:
    map-get($color-theme, grey-700),
);

$points-transaction-module-theme: (
  pt-chart-legend--earn-color: map-get($color-theme, primary-300),
  pt-chart-legend--redeem-color: map-get($color-theme, primary-400),
  pt-chart--earn-color: map-get($color-theme, primary-300),
  pt-chart--grid-color: map-get($color-theme, grey-100),
  pt-chart--label-color: map-get($color-theme, grey-500),
  pt-chart--label-empty-color: map-get($color-theme, grey-100),
  pt-chart--inactive-earn-color: map-get($color-theme, grey-600),
  pt-chart--inactive-redeem-color: map-get($color-theme, grey-100),
  pt-chart--mobile-tooltip-color: map-get($color-theme, primary-100),
  pt-chart--redeem-color: map-get($color-theme, primary-400),
  pt-chart--tooltip-background-color: map-get($color-theme, 'background'),
  pt-chart--tooltip-border-color: map-get($color-theme, 'background'),
  pt-chart--tooltip-box-shadow-color: rgba(map-get($color-theme, grey-100), 0.7),
  pt-chart--tooltip-text-color: map-get($color-theme, grey-500),
  pt-date-picker--button-inrange-background-color:
    rgba(map-get($color-theme, primary-600), 10%),
  pt-date-range-input--input-border-color: map-get($color-theme, grey-100),
  pt-date-range-input--input-disabled-text-color:
    map-get($color-theme, 'disabled'),
  pt-date-range-input--main-input-border-color: map-get($color-theme, grey-100),
  pt-date-range-input--input-disabled-border-color:
    map-get($color-theme, grey-100),
  pt-date-range-input--datepicker-section-border-color:
    map-get($color-theme, primary-200),
  pt-date-range-input--dropdown-content-border-color:
    map-get($color-theme, primary-200),
  pt-date-range-input--input-section-background-color:
    map-get($color-theme, primary-200),
  pt-date-range-input--input-section-date-input-box-shadow-color:
    map-get($color-theme, grey-100),
  pt-detailed-spend-desktop-dialog--header-color:
    map-get($color-theme, primary-400),
  pt-detailed-spend-desktop-dialog--content-item-odd-child-bg-color:
    map-get($color-theme, grey-600),
  pt-detailed-spend-mobile-dialog--header-color:
    map-get($color-theme, primary-400),
  pt-detailed-spend-mobile-dialog--content-item-odd-child-bg-color:
    map-get($color-theme, grey-600),
  pt-history-list-desktop--history-list-item-alternate-background-color:
    map-get($color-theme, 'background'),
  pt-history-list-desktop--loading-boxes-background-color:
    map-get($color-theme, grey-100),
  pt-history-list-item--confirmed-color: map-get($color-theme, primary-400),
  pt-history-list-item--expand-text-active-color:
    map-get($color-theme, primary-600),
  pt-history-list-mobile--history-list-item-wrapper-border-color:
    map-get($color-theme, grey-100),
  pt-history-list-mobile--loading-boxes-background-color:
    map-get($color-theme, grey-100),
  pt-empty-expiring-points--border-color: map-get($color-theme, grey-100),
  pt-page-size-selector--button-border-color: map-get($color-theme, grey-100),
  pt-page-size-selector--button-text-color: map-get($color-theme, grey-100),
  pt-tile-title--text-color: map-get($color-theme, grey-500),
);

$popup-module-theme: (
  'popup-dialog--overlay-background-color':
    rgba(map-get($color-theme, 'shadow'), 70%),
);

$redeem-cash-module-theme: (
  rc-checkout-form--section-border-color: map-get($color-theme, primary-200),
  rc-review-body--review-body-information-border-color:
    map-get($color-theme, grey-100),
  rc-review-breakdown--divider-border-color: map-get($color-theme, grey-100),
  rc-review-form--terms-link-text-active-color:
    map-get($color-theme, primary-600),
  rc-success-points-transaction-status--redemption-id-text-color:
    map-get($color-theme, primary-400),
);

$third-party-libs-theme: (
  'mat-checkbox--checked-color': map-get($color-theme, 'primary-400'),
  'mat-radio-button--checked-color': map-get($color-theme, 'primary-400'),
  mat-input--border-color: map-get($color-theme, grey-100),
  mat-input--empty-text-color: map-get($color-theme, grey-200),
  mat-input--focused-border-color: #003da6,
  mat-input--focused-label-color: #003da6,
);

$transfer-connect-module-theme: (
  tc-checkout-form--section-border-color: map-get($color-theme, primary-200),
  tc-checkout-membership-detail-form--loyalty-program-name-link-active-color:
    map-get($color-theme, primary-600),
  tc-checkout-transfer-amount-form--target-currency-label-color:
    map-get($color-theme, primary-400),
  tc-checkout-terms-list--list-item-loading-bg-color:
    map-get($color-theme, grey-100),
  tc-checkout-terms-list--special-offer-information-bg-color:
    map-get($color-theme, primary-200),
  tc-loyalty-programs-category-filter--category-option-text-active-color:
    map-get($color-theme, primary-400),
  tc-loyalty-programs-category-filter--category-option-button-border-color:
    map-get($color-theme, grey-100),
  tc-loyalty-programs-category-filter--category-option-list-border-bottom-color:
    map-get($color-theme, grey-100),
  tc-loyalty-programs-list-item-desktop--equivalent-points-color:
    map-get($color-theme, primary-400),
  tc-loyalty-programs-list-item-mobile--equivalent-points-color:
    map-get($color-theme, primary-400),
  tc-success-detail--transfer-details-border-color:
    map-get($color-theme, $key: grey-100),
  tc-success-points-transaction-status--confirmed-text-color:
    map-get($color-theme, $key: primary-400),
  tc-review-form--terms-link-text-active-color:
    map-get($color-theme, primary-600),
);

$benefit-module-theme: (
  benefit-membership-detail-form--benefit-name-link-active-color:
    map-get($color-theme, primary-200),
  benefit-membership-detail-form--benefit-name-link-hover-color:
    map-get($color-theme, primary-300),
  benefit-success-points-transaction-status--icon-color:
    map-get($color-theme, warn),
);

$widgets-theme: (
  widgets-checkout-wizard--list-item-active-color:
    map-get($color-theme, primary-400),
  widgets-checkout-wizard--list-item-active-number-color:
    map-get($color-theme, 'background'),
  widgets-checkout-wizard--list-item-color: map-get($color-theme, grey-100),
  widgets-checkout-wizard--list-item-number-active-bg-color:
    map-get($color-theme, primary-400),
  widgets-checkout-wizard--list-item-number-active-border-color:
    map-get($color-theme, primary-400),
  widgets-checkout-wizard--list-item-number-bg-color:
    map-get($color-theme, 'transparent'),
  widgets-checkout-wizard--list-item-number-border-color:
    map-get($color-theme, grey-100),
  widgets-checkout-wizard--progress-line-active-bg-color:
    map-get($color-theme, primary-400),
  widgets-checkout-wizard--progress-line-bg-color:
    map-get($color-theme, grey-100),
);

$component-theme: map-collect(
  $core-module-theme,
  $redeem-cash-module-theme,
  $shared-module-theme,
  $payment-module-theme,
  $widgets-theme
);

@include widgets-checkout-wizard-theme($color-theme, $component-theme);
@include rd-redeem-cash-module-theme($color-theme, $component-theme);

:root {
  @include generate-theme-variable-from-map($color-theme);
  @include generate-theme-variable-rgb-from-map($color-theme, [shadow]);

  // general variable
  --border-color: #d5d8d8;
  --disabled-text-color: #d5d8d8;
  --error-color: #e93936;
  --focus-color: #f6871f;
  --link-color: #003da6;
  --link-hover-color: #0c2340;
  --progress-color: #ebfbf2;
  --success-color: #003da6;
  --text-color: #4d4e53;
  --warning-color: #f6871f;

  // TODO: Will replace the mixin with generated theme variable
  // Benefit theme configuration
  @include generate-theme-variable-from-map($benefit-module-theme);
  @include generate-theme-variable-from-map($points-transaction-module-theme);

  // Transfer connect theme
  @include generate-theme-variable-from-map($transfer-connect-module-theme);

  // Gift card theme variables
  @include generate-theme-variable-from-map($gift-card-module-theme);

  // Payment theme variables
  @include generate-theme-variable-from-map($payment-module-theme);

  // Popup theme variables
  @include generate-theme-variable-from-map($popup-module-theme);

  // 3rt party components theme variables
  @include generate-theme-variable-from-map($third-party-libs-theme);
}
