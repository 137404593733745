@import 'wl-typography-utils';

@mixin rd-benefit-success-detail-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-benefit-success-detail {
    .submitted-on {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-success-detail--submitted-on-typeface',
        'text-emphasis-2-desktop'
      );
    }
  }
}
