@import 'wl-color-utils';

@mixin rd-rc-success-detail-theme($color-theme, $component-theme) {
  $rc-success-detail--background-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-success-detail--background-color,
    'background'
  );
  $rc-success-detail--text-color: rd-comp-theme(
    $color-theme,
    $component-theme,
    rc-success-detail--text-color,
    grey-500
  );

  .rd-rc-success-detail {
    background-color: $rc-success-detail--background-color;
    color: $rc-success-detail--text-color;
  }
}
