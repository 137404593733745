@import 'wl-typography-utils';

@mixin rd-rc-success-detail-typography($base-typography, $tenant-typography) {
  .rd-rc-success-detail {
    .redeem-cash-delivery-message {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-detail--delivery-message-typeface',
        'text-emphasis-2-desktop'
      );
    }

    .submitted-on {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-detail--submitted-on-typeface',
        'text-desktop'
      );
    }

    .redeem-amount {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-detail--submitted-on-typeface',
        'text-emphasis-1-desktop'
      );
    }

    .credit-disclaimer {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-detail--credit-disclaimer-typeface',
        'text-desktop'
      );
    }

    .emphasis {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'rc-success-detail--emphasis-typeface',
        'text-emphasis-1-desktop'
      );
    }
  }
}
