@import 'mixins';
@import 'wl-typography-utils';

@mixin rd-benefit-membership-detail-form-typography(
  $base-typography,
  $tenant-typography
) {
  .rd-benefit-membership-detail-form {
    .benefit-name {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-membership-detail-form--benefit-name-typeface-desktop',
        'text-emphasis-1-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-membership-detail-form--benefit-name-typeface-mobile',
          'text-emphasis-1-mobile'
        );
      }
    }

    .enroll-now-container {
      @include rd-typography-style(
        $base-typography,
        $tenant-typography,
        'benefit-membership-detail-form--enroll-now-typeface-desktop',
        'text-desktop'
      );

      @include screen-until-tablet {
        @include rd-typography-style(
          $base-typography,
          $tenant-typography,
          'benefit-membership-detail-form--enroll-now-typeface-mobile',
          'text-mobile'
        );
      }
    }
  }
}
