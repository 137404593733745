@import './fab-colors';

$primary-theme: (
  50: $blue-300,
  100: $blue-300,
  200: $blue-300,
  300: $blue-300,
  400: $blue-300,
  500: $blue-400,
  600: $blue-400,
  700: $blue-400,
  800: $blue-400,
  900: $blue-400,
  A100: $blue-200,
  A200: $blue-200,
  A400: $blue-400,
  A700: $blue-400,
  contrast: (
    50: $white,
    100: $white,
    200: $white,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $white,
    A200: $white,
    A400: $white,
    A700: $white,
  ),
);

$accent-theme: (
  50: $blue-400,
  100: $blue-400,
  200: $blue-400,
  300: $blue-400,
  400: $blue-400,
  500: $blue-400,
  600: $blue-400,
  700: $blue-400,
  800: $blue-400,
  900: $blue-400,
  A100: $blue-400,
  A200: $blue-400,
  A400: $blue-400,
  A700: $blue-400,
  contrast: (
    50: $black,
    100: $black,
    200: $white,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $white,
    A200: $white,
    A400: $white,
    A700: $white,
  ),
);

$warn-theme: (
  50: $red-200,
  100: $red-200,
  200: $red-200,
  300: $red-200,
  400: $red-200,
  500: $red-200,
  600: $red-200,
  700: $red-200,
  800: $red-200,
  900: $red-200,
  A100: $red-200,
  A200: $red-200,
  A400: $red-200,
  A700: $red-200,
  contrast: (
    50: $white,
    100: $white,
    200: $white,
    300: $white,
    400: $white,
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: $white,
    A200: $white,
    A400: $white,
    A700: $white,
  ),
);

// Change outlines of text input boxes when being hovered over
.mat-form-field-appearance-outline .mdc-notched-outline {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $grey-100;
  }
}

// Change disabled text color inside material input fields
.mat-mdc-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
  .mat-form-field-infix::after {
  color: $grey-100;
}
